import DefaultContainer from '@/common/container/ContentContainer';
import { LDFeatureFlag } from '@/infra/launchdarkly/LDFeatureFlags';
import HomePage from '@/pages/HomePage';
import SignIn from '@/pages/auth/SignIn';
import React, { Suspense } from 'react';
import { Navigate } from 'react-router';

const buildElement = (Component: React.LazyExoticComponent<React.ComponentType<any>>) => {
    return <Suspense fallback={<span></span>}><Component /></Suspense>;
};

const ROLES_ADMIN = ["ROLE_VS_ADMIN"];
const ROLES_ADMIN_CREATOR = ["ROLE_VS_ADMIN", "ROLE_VS_CREATOR"];

interface IRoute {
    index?: boolean;
    path?: string;
    element: JSX.Element;
    roles: string[];
    featureFlag?: LDFeatureFlag;
}

const CONTENT_ROUTES: IRoute[] = [
    {
        index: true,
        element: <HomePage />,
        roles: ROLES_ADMIN_CREATOR,
    },
    {
        path: "/motion/work",
        element: buildElement(React.lazy(() => import('./pages/template/work/TemplateWorkPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/motion/group/manage",
        element: buildElement(React.lazy(() => import('./pages/template/group/TemplateGroupManagePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/motion/group/recommend",
        element: buildElement(React.lazy(() => import('./pages/template/group/recommend/TemplateGroupRecommendGroupPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/motion/u2v/manage",
        element: buildElement(React.lazy(() => import('./pages/template/u2v/U2VManage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/motion/t2v/manage",
        element: buildElement(React.lazy(() => import('./pages/template/t2v/T2VManage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/motion/localization",
        element: buildElement(React.lazy(() => import('./pages/template/localize/video/VideoTemplateLocalizePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/motion/u2v/dpcut",
        element: buildElement(React.lazy(() => import('./pages/template/u2v/dpcut/U2VDpCutPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/motion/t2v/dpcut",
        element: buildElement(React.lazy(() => import('./pages/template/u2v/dpcut/U2VDpCutPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/u2v/vcat",
        element: buildElement(React.lazy(() => import('./pages/template/u2v/U2VtoVcat'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/u2i/result",
        element: buildElement(React.lazy(() => import('./pages/template/u2i/U2IResultPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/u2i/template",
        element: buildElement(React.lazy(() => import('./pages/template/u2i/U2ITemplate'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/u2i/variation/recommend",
        element: buildElement(React.lazy(() => import('./pages/template/u2i/U2IRecommendSize'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/u2i/variation/fixed",
        element: buildElement(React.lazy(() => import('./pages/template/u2i/U2IVariationFixed'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/u2i/variation/:templateId",
        element: buildElement(React.lazy(() => import('./pages/template/u2i/U2IVariation'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/u2i/create",
        element: buildElement(React.lazy(() => import('./pages/template/u2i/U2ICreate'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/u2i/create/:templateId",
        element: buildElement(React.lazy(() => import('./pages/template/u2i/U2ICreate'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/u2i/remove-bg-history",
        element: buildElement(React.lazy(() => import('./pages/template/u2i/U2IRemoveBgHistory'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/u2i/customSize",
        element: buildElement(React.lazy(() => import('./pages/template/u2i/U2ICustomSize'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/report/image/result",
        element: buildElement(React.lazy(() => import('./pages/media/report/ImageReportPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/asset/media",
        element: buildElement(React.lazy(() => import('./pages/resource/MediaSourceManage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/asset/custom-font",
        element: buildElement(React.lazy(() => import('./pages/media/customFont/CustomFontPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/asset/font",
        element: buildElement(React.lazy(() => import('./pages/resource/FontManage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/asset/audio",
        element: buildElement(React.lazy(() => import('./pages/resource/AudioManage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/asset/color",
        element: buildElement(React.lazy(() => import('./pages/resource/ColorManage'))),
        roles: ROLES_ADMIN,
    }, {
        path: "/directory",
        element: buildElement(React.lazy(() => import('./pages/resource/directory/DirectoryPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/directory/item",
        element: buildElement(React.lazy(() => import('./pages/resource/directory/DirectoryItemPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/asset/recommend-text",
        element: buildElement(React.lazy(() => import('./pages/resource/recommend/RecommendTextManage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/tag/manage",
        element: buildElement(React.lazy(() => import('./pages/template/tag/TemplateGroupTagManage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/user/manage",
        element: buildElement(React.lazy(() => import('./pages/user/UserManagePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/user/company/manage",
        element: buildElement(React.lazy(() => import('./pages/user/company/CompanyManagePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/team/manage",
        element: buildElement(React.lazy(() => import('./pages/user/team/TeamManagePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/role/manage",
        element: buildElement(React.lazy(() => import('./pages/role/RoleManage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/worklimit",
        element: buildElement(React.lazy(() => import('./pages/worklimit/WorkLimitGroupList'))),
        roles: ROLES_ADMIN,
    },
    // {
    //     path: "/order/user",
    //     element: buildElement(React.lazy(() => import('./pages/vat/order/user/OrderManage'))),
    //     roles: ROLES_ADMIN,
    // },
    // {
    //     path: "/product/manage",
    //     element: buildElement(React.lazy(() => import('./pages/product/ProductSpecManage'))),
    //     roles: ROLES_ADMIN,
    // },
    {
        path: "/subscription/type",
        element: buildElement(React.lazy(() => import('./pages/subscription/SubscriptionType'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/subscription/type/description",
        element: buildElement(React.lazy(() => import('./pages/subscription/SubscriptionTypeDescription'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/subscription",
        element: buildElement(React.lazy(() => import('./pages/subscription/SubscriptionList'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/coupon/type",
        element: buildElement(React.lazy(() => import('./pages/coupon/CouponTypeList'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/payment",
        element: buildElement(React.lazy(() => import('./pages/payment/PaymentList'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/media/source",
        element: buildElement(React.lazy(() => import('./pages/media/source/MediaSourcePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/media/result",
        element: buildElement(React.lazy(() => import('./pages/media/result/MediaResultManage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/report/media/result",
        element: buildElement(React.lazy(() => import('./pages/media/report/MediaReportPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/report/ds/scrap",
        element: buildElement(React.lazy(() => import('./pages/ds/report/UrlScrapReportPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/report/imagine/history",
        element: buildElement(React.lazy(() => import('./pages/imagine/report/ImagineReportPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: '/report/posting',
        element: buildElement(React.lazy(() => import("./pages/sample/sns/SnsPostingResultPage"))),
        roles: ROLES_ADMIN
    },
    {
        path: "/guide/manage",
        element: buildElement(React.lazy(() => import('./pages/guide/GuideManagePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/guide/group/manage",
        element: buildElement(React.lazy(() => import('./pages/guide/GuideGroupManagePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/sample/layout/default",
        element: buildElement(React.lazy(() => import('./pages/sample/layout/DefaultPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/sample/layout/row2grid",
        element: buildElement(React.lazy(() => import('./pages/sample/layout/Row2Grid'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/sample/layout/timeline",
        element: buildElement(React.lazy(() => import('./pages/sample/layout/SampleTimeline'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/sample/modal",
        element: buildElement(React.lazy(() => import('./pages/sample/modal/SampleModal'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/sample/component",
        element: buildElement(React.lazy(() => import('./pages/sample/document/DocumentPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/sample/component/new",
        element: buildElement(React.lazy(() => import('./pages/sample/document/NewDocumentPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/sample/gdrive",
        element: buildElement(React.lazy(() => import('./pages/sample/gdrive/GoogleDrivePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/sample/fb",
        element: buildElement(React.lazy(() => import('./pages/sample/fb/FBPage'))),
        roles: ROLES_ADMIN,
    },
    // {
    //     path: "/sample/sns/calendar",
    //     element: buildElement(React.lazy(() => import('./pages/sample/sns/calendar/CalendarPage'))),
    //     roles: ROLES_ADMIN,
    // },
    {
        path: "/test/payment",
        element: buildElement(React.lazy(() => import('./pages/test/PaymentTestPage'))),
        roles: ROLES_ADMIN,
    }, {
        path: "/test/list",
        element: buildElement(React.lazy(() => import('./pages/test/ListSample'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/media/gallery",
        element: buildElement(React.lazy(() => import('./pages/media/showcase/ShowcaseManagePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/media/showcase",
        element: buildElement(React.lazy(() => import('./pages/media/showcase/ShowcaseGalleryPage'))),
        roles: ROLES_ADMIN,
    },
    // {
    //     path: "/order/enterprise/reserve",
    //     element: buildElement(React.lazy(() => import('./pages/vat/order/reserve/OrderReserveManagePage'))),
    //     roles: ROLES_ADMIN,
    // },
    // {
    //     path: "/order/change/product",
    //     element: buildElement(React.lazy(() => import('./pages/vat/order/change_product/OrderChangeProductPage'))),
    //     roles: ROLES_ADMIN,
    // },
    {
        path: "/media/editor",
        element: buildElement(React.lazy(() => import('./pages/media/editor/ImageEditorPage'))),
        roles: ROLES_ADMIN,
    },
    // {
    //     path: "/order/feature/limit",
    //     element: buildElement(React.lazy(() => import('./pages/vat/order/feature_limit/FeatureLimitManagePage'))),
    //     roles: ROLES_ADMIN,
    // },
    {
        path: "/test/ZustandTestPage",
        element: buildElement(React.lazy(() => import('./pages/test/ZustandTestPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/report/service",
        element: buildElement(React.lazy(() => import('./pages/report/VcatServiceReportPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/report/service/grid",
        element: buildElement(React.lazy(() => import('./pages/report/VcatReportAgGrid'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/test/text/color",
        element: buildElement(React.lazy(() => import('./pages/test/TipTapText'))),
        roles: ROLES_ADMIN,
    },
    // {
    //     path: "/order/global/request",
    //     element: buildElement(React.lazy(() => import('./pages/vat/order/global_order_request/GlobalOrderReuqest'))),
    //     roles: ROLES_ADMIN,
    // },
    {
        path: "/team/custom-feature/manage",
        element: buildElement(React.lazy(() => import('./pages/user/custom-feature/CustomFeaturePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: '/ai/gen/history',
        element: buildElement(React.lazy(() => import('./pages/ds/genai/GenAiHistory'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/ai/gpt/prompt",
        element: buildElement(React.lazy(() => import('./pages/ds/gpt/GptPromptManagePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/ai/gpt/result",
        element: buildElement(React.lazy(() => import('./pages/ds/gpt/GptPromptResultPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/ai/tts",
        element: buildElement(React.lazy(() => import('./pages/ds/tts/TTSPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/ai/gpt/history",
        element: buildElement(React.lazy(() => import('./pages/ds/gpt/GptHistoryManagePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/ai/dalle",
        element: buildElement(React.lazy(() => import('./pages/ds/dalle/DalleManagePage'))),
        roles: ROLES_ADMIN
    },
    {
        path: "/ai/dalle/history",
        element: buildElement(React.lazy(() => import('./pages/ds/dalle/history/DalleHistoryManagePage'))),
        roles: ROLES_ADMIN
    },
    {
        path: "/ai/imagine/history",
        element: buildElement(React.lazy(() => import('./pages/imagine/gallery/ImagineGalleryPage'))),
        roles: ROLES_ADMIN
    },
    {
        path: "/ai/imagine/issue",
        element: buildElement(React.lazy(() => import('./pages/imagine/issue/ImagineIssuePage'))),
        roles: ROLES_ADMIN
    },
    {
        path: "/ai/imagine/issue/*",
        element: buildElement(React.lazy(() => import('./pages/imagine/issue/ImagineIssueDetail'))),
        roles: ROLES_ADMIN
    },
    {
        path: "/ai/copygen/api",
        element: buildElement(React.lazy(() => import('./pages/copygen/CopyGenApiVersionManagePage'))),
        roles: ROLES_ADMIN
    },
    {
        path: "/partner/manage",
        element: buildElement(React.lazy(() => import('./pages/user/partner/PartnerManagePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/partner/test",
        element: buildElement(React.lazy(() => import('./pages/partner/PartnerApiTestPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/partner/iframe",
        element: buildElement(React.lazy(() => import('./pages/partner/PartnerIframeTestPage'))),
        roles: ROLES_ADMIN,
    },
    // {
    //     path: "/sample/sns-auth",
    //     element: buildElement(React.lazy(() => import('./pages/sample/sns-auth/SnsAuthTestPage'))),
    //     roles: ROLES_ADMIN
    // },
    {
        path: "/sample/sns-auth/start",
        element: buildElement(React.lazy(() => import('./pages/sample/sns-auth/SnsAuthStartPage'))),
        roles: ROLES_ADMIN
    },
    {
        path: "/sample/sns-auth/complete",
        element: buildElement(React.lazy(() => import('./pages/sample/sns-auth/SnsAuthCompletePage'))),
        roles: ROLES_ADMIN
    },
    {
        path: "/sample/stripe",
        element: buildElement(React.lazy(() => import('./pages/sample/stripe/StripeSamples'))),
        roles: ROLES_ADMIN,
    },
    {
        path: '/sample/posting',
        element: buildElement(React.lazy(() => import('./pages/sample/sns/SnsPostingTestPage'))),
        roles: ROLES_ADMIN
    },
    {
        path: "/media/editor/v2",
        element: buildElement(React.lazy(() => import('./pages/media/editorV2/SamplePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/ados/workspace",
        element: buildElement(React.lazy(() => import('./pages/ados/workspace/AdWorkSpaceManage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/ados/channels",
        element: buildElement(React.lazy(() => import('./pages/ados/workspace/AdLinkedChannels'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/ados/creatives",
        element: buildElement(React.lazy(() => import('./pages/ados/campaign/AdCreatives'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/ai/copygen/report",
        element: buildElement(React.lazy(() => import('./pages/copygen/CopyGenApiHistoryReportPage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/blog/posts",
        element: buildElement(React.lazy(() => import('./pages/blog/BlogContentManagePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/email/template",
        element: buildElement(React.lazy(() => import('./pages/email/EmailTemplatePage'))),
        roles: ROLES_ADMIN,
    },
    {
        path: "/black/job-detail",
        element: buildElement(React.lazy(() => import('./pages/test/LagrangeJobDetail'))),
        roles: ROLES_ADMIN,
    }
];

export const AppRouteMap = CONTENT_ROUTES.reduce((acc, route) => {
    if (route.path) acc[route.path] = route;
    return acc;
}, {} as Record<string, IRoute>);

export const AppRoutes = [
    {
        path: "/",
        element: <DefaultContainer />,
        children: CONTENT_ROUTES
    },
    {
        path: "/login",
        element: <SignIn />
    },
    {
        path: "*",
        element: <Navigate to="/" />
    },
];
