import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';

export interface QueryFilter {
    limit?: number;
    offset?: number;
    [key: string]: any;
}

export interface ColumnVisibleOrder {
    colId: string;
    visible: boolean;
}

class FileSize {
    KB = 1024;
    MB = 1024 * this.KB;
    GB = 1024 * this.MB;
}

const FILE_UNIT = new FileSize();
console.log("location.host", `[${location.host}]`);
let PUBLIC_RESOURCE_INDEX = 0;
class Utils {

    private static playedAudio: HTMLAudioElement;

    static getSessionData = (key: string, defaultValue: any) => {
        const val = sessionStorage.getItem(key);

        if (val) {
            try {
                const result = JSON.parse(val);
                console.log("val", result);
                return result;
            } catch (e) {
                console.error('get session data error', e);
            }
        }
        return defaultValue;
    };
    static setSessionData = (key: string, value: any) => {
        console.log("save session data", value);
        sessionStorage.setItem(key, JSON.stringify(value));
    };
    static isReal = () => {
        return import.meta.env.VITE_VCAT_ENV ? import.meta.env.VITE_VCAT_ENV === 'real' : false;
    };

    static isAlpha = () => {
        return import.meta.env.VITE_VCAT_ENV ? import.meta.env.VITE_VCAT_ENV === 'alpha' || import.meta.env.VITE_VCAT_ENV === 'local' : false;
    };

    static setPlayedAudio = (audio: HTMLAudioElement) => {
        if (Utils.playedAudio && Utils.playedAudio !== audio) {
            if (!Utils.playedAudio.paused) {
                Utils.playedAudio.pause();
            }
        }
        Utils.playedAudio = audio;
    };

    static toggleMuteMedia = (mute: boolean) => {
        if (mute) {
            localStorage.setItem(`player:mute`, 'true');
        } else[
            localStorage.removeItem(`player:mute`)
        ];

    };

    static isMuteMedia = () => {
        return localStorage.getItem(`player:mute`) == 'true';
    };

    public static getLang = () => {
        return localStorage.getItem('lang') || window.navigator.language.split('-')[0] || 'ko';
    };


    public static toInt = (value: number | string) => {
        try {
            return parseInt(`${value}`);
        } catch (e) {
            return 0;
        }
    };

    public static toFloat = (value: number | string, decimalPoint?: number) => {
        try {
            if (decimalPoint !== undefined) {
                return parseFloat(parseFloat(`${value}`).toFixed(decimalPoint));
            }
            return parseFloat(`${value}`);
        } catch (e) {
            return 0;
        }
    };

    public static readonly buildEnvironmentHeader = () => {
        const headers: { [key: string]: string; } = {};
        if (location.host === 'localhost:5500') {
            headers['x-vcat-dev'] = "record";
        }
        return headers;
        // "x-play-dev": window.location.host === 'localhost' ? "true" : "false";
    };

    static isMobileDevice = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };

    static getMediaUrl = (url: string) => {
        if (!url) {
            return '';
        }
        if (url.startsWith('https://')) {
            return url;
        }

        url = url.replaceAll('//', '/');

        return `/${url}`;
    };
    static shuffle = (array: Array<any>) => {
        var m = array.length,
            t,
            i;
        while (m) {
            i = Math.floor(Math.random() * m--);
            t = array[m];
            array[m] = array[i];
            array[i] = t;
        }

        return array;
    };

    public static readonly isEmpty = (val: any) => {
        if (val === null) return true;
        if (typeof val === 'undefined') return true;
        if (typeof val === 'string' && val === '') return true;
        if (Array.isArray(val) && val.length < 1) return true;
        if (typeof val === 'object' && val.constructor.name === 'Object' && Object.keys(val).length < 1 && Object.getOwnPropertyNames(val).length < 1) return true;
        if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true;
    };

    public static readonly isBoolean = (val: any) => {
        return (val === true || val === false);
    };

    public static readonly getCookie = (cookieName: string): string | undefined => {
        const name = cookieName + "=";
        const cDecoded = decodeURIComponent(document.cookie); //to be careful
        const cArr = cDecoded.split('; ');
        let res;
        cArr.forEach(val => {
            if (val.indexOf(name) === 0) res = val.substring(name.length);
        });
        return res;
    };

    public static readonly splitArray = (arr: Array<any>, len: number) => {
        let chunks = [], i = 0, n = arr.length;
        while (i < n) {
            chunks.push(arr.slice(i, i += len));
        }
        return chunks;
    };


    public static readonly isString = (value: any) => {
        return typeof value === 'string' || value instanceof String;
    };


    public static readonly isNumber = (val: any) => {
        return typeof val === "number" && val === val;
    };

    public static readonly isNaN = (val: any) => {
        return typeof val === "number" && val !== val;
    };

    public static readonly encodeBase64 = (str: string) => {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1: any) {
                return String.fromCharCode(('0x' + p1) as any);
            }));
    };

    public static readonly decodeBase64 = (str: string) => {
        //https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    };

    public static readonly decodeUrl = (str?: string) => {
        if (!str) return str;
        try {
            return decodeURIComponent(str).replaceAll('http://', '')
                .replaceAll('https://', '')
                .replaceAll('www.', '');
        } catch (e) {
            console.error('decode url error ', str, e);
            return str.replaceAll('http://', '')
                .replaceAll('https://', '')
                .replaceAll('www.', '');;
        }
    };

    public static readonly formatDate = (src: number | Date, type: "DATETIME" | "DATE") => {
        let date: Date;
        if (!src) {
            return '';
        }
        if (src instanceof Date) {
            date = src;
        } else {
            date = new Date(src);
        }

        switch (type) {
            case "DATE":
                return date.toLocaleDateString();
            case "DATETIME":
                return date.toLocaleString();
        }
    };

    public static readonly formatDuration = (duration: number | string) => {
        let nDuration = 0;
        if (typeof duration === 'string') {
            nDuration = parseFloat(duration as string);
        } else {
            nDuration = duration as number;
        }
        let [min, sec] = [~~(nDuration / 60), nDuration % 60];
        min = parseInt(min.toFixed(0));
        sec = parseInt(sec.toFixed(0));
        if (min === 0) {
            return sec + 's';
        }
        return min + ':' + (sec < 10 ? '0' : '') + sec;
    };

    public static readonly addComma = (num: number | string): string => {
        if (!num) {
            return "0";
        }



        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };

    public static readonly humanReadableFileSize = (size: number, withKB: boolean = false) => {
        if (size < 0) {
            return undefined;
        }
        if (size < FILE_UNIT.MB && withKB) {
            return `${(size / FILE_UNIT.KB).toFixed(0)} KB`;
        } else if (size < FILE_UNIT.MB) {
            return `0 MB`;
        } else if (size < FILE_UNIT.GB) {
            return `${(size / FILE_UNIT.MB).toFixed(0)} MB`;
        } else {
            return `${(size / FILE_UNIT.GB).toFixed(2)} GB`;
        }
    };

    public static readonly priceToString = (value: string, prefix?: string) => {
        if (prefix) {
            return prefix + ' ' + value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원';
    };

    public static humanReadableDateTime = (dateVal: number | string | Date): string => {
        let date: any = dateVal;

        const now = new Date().getTime();
        if (Utils.isNumber(dateVal)) {
            if (now - (date as number) < 1000 * 60 * 60 * 24) {
                let sec = (now - (date as number)) / 1000;
                if (sec < 0) {
                    sec = 0;
                }
                const under1Day = new Date(now - (date as number));
                if (sec < 60) {
                    return `${sec.toFixed(0)}초 전`;
                } else if (sec < 60 * 60) {
                    return `${(sec / 60).toFixed(0)}분 전`;
                } else {
                    return `${(sec / (60 * 60)).toFixed(0)}시간 전`;
                }
            }
            date = new Date(dateVal);
        } else {
            date = dateVal;
        }
        if (!date) {
            return '';
        }

        return `${(date as Date).getFullYear()}.${`${(date as Date).getMonth() + 1}`.padStart(2, '0')}.${`${(
            date as Date
        ).getDate()}`.padStart(2, '0')}.`;
    };

    public static getByteSize = (val: string) => {
        let totalSize = 0;
        for (let char of val) {
            if (char.charCodeAt(0) > 128) {
                totalSize += 2;
            } else {
                totalSize += 1;
            }
        }
        return totalSize;
    };

    public static removeLineEnd = (str: string) => {
        return str.replace(/\n/g, ' ').replace('\n', ' ').replace('</br>', ' ');
    };

    public static getResourceUrl = (url: string) => {
        if (!url) {
            return '';
        }
        if (url.startsWith('https://')) {
            return url;
        }
        return `/${url}`;

    };

    private static getPublicResourceDomain() {
        PUBLIC_RESOURCE_INDEX = PUBLIC_RESOURCE_INDEX % 3 + 1;
        return `https://resource-${PUBLIC_RESOURCE_INDEX}.${import.meta.env.VITE_VCAT_BUCKET_BASE}`;
    }

    private static getUploadResourceDomain() {
        return `https://noname.${import.meta.env.VITE_VCAT_BUCKET_BASE}`;
    }

    public static getPublicResourceUrl = (url: string) => {
        if (!url) return '';
        if (url.startsWith('https://')) return url;
        return `${Utils.getPublicResourceDomain()}/${url}`;
    };

    public static getUrlFromS3Object = (bucket: string, path: string) => {
        if (bucket.startsWith('upload')) return `${this.getUploadResourceDomain()}/${path}`;
        if (bucket.startsWith('public')) return this.getPublicResourceUrl(path);
        return this.getResourceUrl(path);
    };

    public static isOverMaxLength = (text: Array<string>, maxLength: number) => {
        return text.filter(e => Utils.getByteSize(e) > maxLength * 4).length > 0;
    };

    public static readonly toggleArrayValue = (array: Array<any> | undefined | null, value: any) => {
        if (!array) {
            array = [];
        }
        const index = array.indexOf(value, 0);
        if (index > -1) {
            array.splice(index, 1);
        } else {
            array.push(value);
        }
        return array;
    };

    public static readonly removeItemFromArrayIndex = (array: Array<any> | undefined, index: number) => {
        if (!array) {
            return [];
        }
        array.splice(index, 1);
        return array;
    };

    static roundToDecimalPlaces = (num: number, decimalPlaces: number = 2) => {
        const factor = Math.pow(10, decimalPlaces);
        return Math.round(num * factor) / factor;
    };

    public static getEncodedParamObj = (obj: QueryFilter) => {

        let encodeObj: { [key: string]: any; } = {};
        if (!Utils.isEmpty(obj.limit) && !Utils.isEmpty(obj.offset)) {
            encodeObj = {
                limit: obj.limit,
                offset: obj.offset
            };
        }

        Object.entries(obj).forEach((ent) => {
            const [key, value] = ent;
            if (Utils.isEmpty(value)) {
                return;
            }
            if (dayjs.isDayjs(value) || value instanceof Date) {
                encodeObj[key] = encodeURI(value.toISOString());
                return;
            }
            encodeObj[key] = encodeURI(value);
        });

        return encodeObj;
    };


    public static readonly convertToOptionsArray = (array: Array<any>, label: string, value: string) => {
        if (!array) {
            return [];
        }
        const optionsArray = array.map((item) => {
            const _value = item[value];
            const _label = item[label];
            return {
                value: _value,
                label: _label
            };
        });
        return optionsArray;
    };

    public static gcd = (a: number, b: number): number => a % b === 0 ? b : Utils.gcd(b, a % b);

    static rgbToHex = (r: number, g: number, b: number) => {
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    };

    public static keysToCamel = (o: any): any => {
        if (Utils.isObject(o)) {
            const n = {} as Record<string, any>;

            Object.keys(o)
                .forEach((k) => {
                    n[Utils.toCamel(k)] = Utils.keysToCamel(o[k]);
                });

            return n;
        } else if (Utils.isArray(o)) {
            return o.map((i: any) => {
                return Utils.keysToCamel(i);
            });
        }

        return o;
    };

    static isArray = (a: any) => {
        return Array.isArray(a);
    };

    static isObject = (o: any) => {
        return o === Object(o) && !Utils.isArray(o) && typeof o !== 'function';
    };

    static toCamel = (s: string) => {
        return s.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
        });
    };

    public static copyText = async (text: string) => {
        // console.log('Clipboard copyText', text);
        let useClipBoard = true;
        if (navigator && navigator.clipboard) {
            try {
                // console.log('navigator.clipboard');
                await navigator.clipboard.writeText(text);
                return true;
            } catch (error) {
                console.error('text clipboard copy fail ', error);
                // return false;
                //document.execCommand 실행
                useClipBoard = false;
            }

        } else if (document.execCommand) {
            // console.log('Clipboard use document.execCommand', text);
            const textArea = document.createElement("textarea");
            textArea.value = text;

            textArea.style.position = "fixed";
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.width = "2em";
            textArea.style.height = "2em";
            textArea.style.padding = "0";
            textArea.style.border = "none";
            textArea.style.outline = "none";
            textArea.style.boxShadow = "none";
            textArea.style.background = "transparent";

            document.body.appendChild(textArea);

            textArea.select();
            try {
                console.log('ClipboardUtils document.execCommand');
                document.execCommand('copy');
            } catch (err) {
                document.body.removeChild(textArea);
                console.error('text execCommand copy fail ', err);
                return false;
            }

            document.body.removeChild(textArea);
            return true;
        } else {
            console.error("클립보드 복사를 지원하지 않는 브라우저입니다.");
            return false;
        }

        if (!useClipBoard) {
            console.log('ClipboardUtils use document.execCommand', text);
            const textArea = document.createElement("textarea");
            textArea.value = text;

            textArea.style.position = "fixed";
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.width = "2em";
            textArea.style.height = "2em";
            textArea.style.padding = "0";
            textArea.style.border = "none";
            textArea.style.outline = "none";
            textArea.style.boxShadow = "none";
            textArea.style.background = "transparent";

            document.body.appendChild(textArea);

            textArea.select();
            try {
                console.log('ClipboardUtils document.execCommand');
                document.execCommand('copy');
            } catch (err) {
                document.body.removeChild(textArea);
                console.error('text execCommand copy fail ', err);
                return false;
            }

            document.body.removeChild(textArea);
            return true;
        }

    };

    public static readAsArrayBuffer = (blob: Blob) => {
        if (blob.arrayBuffer) {
            return blob.arrayBuffer();
        }

        const reader = new FileReader();
        reader.readAsArrayBuffer(blob);

        return new Promise<ArrayBuffer>((resolve) => {
            reader.addEventListener("load", (event) => {
                if (event.target) {
                    resolve(event.target.result as ArrayBuffer);
                } else {
                    throw new Error("Loaded blob but event.target is null");
                }
            });
        });
    };

    public static readAsDataURL = (blob: Blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);

        return new Promise<String>((resolve) => {
            reader.addEventListener("load", (event) => {
                if (event.target) {
                    resolve(event.target.result as String);
                } else {
                    throw new Error("Loaded blob but event.target is null");
                }
            });
        });
    };

    public static getMd5HahsBase64String = (base64String: string) => {
        return CryptoJS.MD5(CryptoJS.enc.Base64.parse(base64String)).toString();
    };

    public static HexToRgb = (hex: string, alpha?: number) => {
        let r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha !== undefined) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    };
}

export default Utils;